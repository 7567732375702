import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { StyleSheet, css } from 'aphrodite'
import { Material } from 'inno-components/build/Icons'
import { Section } from 'inno-components'
import { scroller, Element } from 'react-scroll'
import Helmet from 'react-helmet'

import Config from '../../Config'
import Classes from '../../styles/classes'

import IconSection from '../../icons/IconSection'

import HeartbeatIcon from '../../icons/Heartbeat'

const styles = StyleSheet.create({
  sectionTop: {
    backgroundColor: Config.colors.quaternary
  },
  sectionWhite: {
    backgroundColor: '#FFFFFF'
  },
  container: {
    minHeight: '300px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    overflowY: 'auto'
  },
  icon: {
    fontSize: '1.3em',
    color: '#FFFFFF'
  }
})

class SocialementRespectueux extends Component {
  onSectionClick = (section) => {
    scroller.scrollTo(section, {
      smooth: true
    })
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        <Helmet
          title={t('head.title')}
          meta={[
            {
              name: 'description',
              content: t('head.meta-description')
            }
          ]}
        />
        <Section className={css(styles.sectionTop)}>
          <div className={css(styles.container)}>
            <IconSection
              title={t('menu.confort')}
              height={170}
              fill={Config.colors.primary}
              onClick={() => this.onSectionClick('confort')}
            >
              <Material.MdAirlineSeatReclineExtra className={css(styles.icon)} />
            </IconSection>
            <IconSection
              title={t('menu.simplicite')}
              height={185}
              fill={Config.colors.secondary}
              onClick={() => this.onSectionClick('simplicite')}
            >
              <Material.MdThumbUp className={css(styles.icon)} />
            </IconSection>
            <IconSection
              title={t('menu.nocivite')}
              height={160}
              fill={Config.colors.thirdary}
              direction="down"
              onClick={() => this.onSectionClick('nocivite')}
            >
              <HeartbeatIcon className={css(styles.icon)} />
            </IconSection>
          </div>
        </Section>
        <Element name="confort" id="confort">
          <Section className={css(Classes.section)}>
            <p>{t('paragraphes.0')}</p>
            <p>{t('paragraphes.1')}</p>
          </Section>
        </Element>
        <Element name="simplicite" id="simplicite">
          <Section className={css(Classes.section)}>
            <h2 className={css(Classes.h2)}>{t('sections.0.titre')}</h2>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.0')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.1')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.2')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.3')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.4')
              }}
            />
          </Section>
        </Element>
        <Element name="nocivite" id="nocivite">
          <Section className={css(Classes.section, styles.sectionWhite)}>
            <h2 className={css(Classes.h2)}>{t('sections.1.titre')}</h2>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.0')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.1')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.2')
              }}
            />
          </Section>
        </Element>
        <Element name="pollution" id="pollution">
          <Section className={css(Classes.section)}>
            <h2 className={css(Classes.h2)}>{t('sections.2.titre')}</h2>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.2.paragraphes.0')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.2.paragraphes.1')
              }}
            />
          </Section>
        </Element>
      </React.Fragment>
    )
  }
}

export default withNamespaces('socialement-respectueux')(SocialementRespectueux)

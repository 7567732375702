import React from 'react'
import { compose, pure } from 'recompose'
import { withNamespaces } from 'react-i18next'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'

import SecondaryLayout from '../../components/layouts/SecondaryLayout'
import SocialementRespectueux from '../../components/assets/SocialementRespectueux'

const CompositionPage = ({ t }) => (
  <SecondaryLayout subtitle={t('titre')} page="ComponentAssetsSocialementRespectueux">
    <SocialementRespectueux />
  </SecondaryLayout>
)

const echance = compose(
  pure,
  SetLanguageFromPage(),
  withNamespaces('socialement-respectueux')
)

export default echance(CompositionPage)
